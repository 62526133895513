import React, { useState, useEffect } from "react"

import { objectDeepClone, getDay, getDate, getMonth, getYear } from "../../../service/helper"

const DatePicker = (props) => {

    const { data, teleCallback } = props

    let Arr = []
    let newArr = []
    let formattedOldArr = []
    const [value, setValue] = useState(0)
    const [selectedData, setSelectedData] = useState({})
    const [displayDates, setDisplayDates] = useState([])
    const [formattedArr, setFormattedArr] = useState([])
    const [calenderMonth, setcalenderMonth] = useState([])

    useEffect(() => {
        formatData();
    }, [data])
    

    const formatData = () => {
        if (data !== undefined && Object.keys(data).length) {
            Object.entries(data).forEach(([key, value], i) => {
                let dataObj = {
                    ogDate: key,
                    day: getDay(key),
                    date: getDate(key),
                    month: getMonth(key),
                    year: getYear(key),
                    data: value
                }
                Arr.push(dataObj)
            });
            
            Arr.forEach((el, i) => {
                newArr.push(el)
                if (i % 7 === 6 || i + 1 === Arr.length) {
                    formattedOldArr.push(newArr)
                    newArr = []
                }
            })
            let selected = null
            Arr.forEach(x => {
                if(x?.data) {
                    for (const [key, value] of Object.entries(x?.data)) {
                        if(value !== null && value?.length) {
                            value.forEach(y => {
                                //if(y && y.available === '1' && y?.to?.length && y?.from?.length) {
                                    if(!selected) selected = x
                                //}
                            })
                        }
                    }
                }
            })
            
            if(selected?.ogDate) {
                setSelectedData(selected)
                //teleCallback(selected)
            }
            let dataArr = formattedOldArr.length ? objectDeepClone(formattedOldArr) : []
            let selDate = null
            dataArr.forEach(el => {
                if(el?.length) {
                    el.forEach(x =>{
                        if(x?.ogDate === selected?.ogDate && selDate === null) {
                            selDate = el
                        }
                    })
                }
            })
            setFormattedArr(dataArr)
            setDisplayDates(selDate)
            setcalenderMonth(selDate?.[0]?.month)
        }
    }

    const onNext = () => {
        setValue(value + 1)
        if (value + 1 < formattedArr.length) {
            setDisplayDates(objectDeepClone(formattedArr[value + 1]))
            setcalenderMonth(objectDeepClone(formattedArr[value + 1])?.[0]?.month)
        } else {
            setValue(formattedArr.length - 1)
        }
    }

    const onPrev = () => {
        setValue(value - 1)
        if (value - 1 >= 0) {
            setDisplayDates(objectDeepClone(formattedArr[value - 1]))
            setcalenderMonth(objectDeepClone(formattedArr[value - 1])?.[0]?.month)
        } else {
            setValue(0)
        }
    }

    const callParent = (val) => {
        setSelectedData(val)
        teleCallback(val)
        setcalenderMonth(val.month)
    }
    return (
        <div className="w-100 calBox">
            <div className="calMonth">
                <span className="calElement today selected">{calenderMonth.length ? calenderMonth : 'No dates available.'}</span>
                <div className="weekdate"></div>
            </div>
            <div className="calYear">
                <span className="calElement today selected">{displayDates?.[0]?.year ? displayDates?.[0]?.year : ''}</span>
            </div>
            <div className="calDay">
                <div className="calElement prev" onClick={onPrev} ></div>
                {displayDates?.length ? displayDates.map(el => {
                    return <div key={el.date} className={`calElement ${selectedData.date === el.date && selectedData.month === el.month ? 'today selected' : ''}`} onClick={()=> {callParent(el)}} style={{width: "87px"}}>
                        <div className="day_num_name">
                            <span className="dayNumber">{el.day}</span>
                            <br />{el.date}
                        </div>
                    </div>
                }) : null}
                <div className="calElement next" onClick={onNext} ></div>
            </div>
        </div>
    )
}

export default DatePicker