import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { graphql, Link, navigate } from "gatsby"
import { useLocation } from '@reach/router'
import toast from "react-hot-toast"
import Modal from "react-bootstrap/Modal"
import PhoneInput from 'react-phone-input-2'
import SimpleReactValidator from "simple-react-validator"
import Select from 'react-select'

import 'react-phone-input-2/lib/style.css'

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import ImageComponent from "../components/pageSections/imageComponent"
import Loader from "../components/addon/Loader"
import DatePicker from "../components/addon/datePicker"
import TeledatePicker from "../components/addon/teledatePicker"


import USER_API from "../api/user"
import API, { initHeaders } from "../api/doctors"
import { format24To12HoursTime, getCookie, setCookie, getLowerCaseNoSpace, acceptOnlyNumbers, generateUniqueId, addMinutesInTime } from "../service/helper"
import useScript from '../service/useScript';
import APIButton from "../components/addon/button/apiButton"
import LinkButton from "../components/addon/button/linkButton"
import CustomLink from '../components/addon/Link'
import MobileVerifyViaOTP from "../components/addon/inputElements/mobileVerifyViaOtp"

import UseCustomValidator from "../service/simpleValidator"

const Profile = (props) => {
    const { pageContext, data } = props
    const location = useLocation()
    const { customMobileValidator } = UseCustomValidator()
    const defaultImage = "/assets/images/dr-profile-img-placeholder.png"
    const [pageInfo, setPageInfo] = useState({})
    let metaTag = []
    const [category, setCategory] = useState("")
    const [speciality, setSpeciality] = useState("")
    const [bookingSlots, setBookingSlots] = useState(null)
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState({})
    // const [selectedTeleDate, setSelectedTeleDate] = useState({})
    const [selectedSlot, setSelectedSlot] = useState("")
    const [selectedDateSlots, setSelectedDateSlots] = useState({})
    const [roomNo, setRoomNo] = useState(null)

    const [showModal, setShowModal] = useState(false)
    const [, forceUpdateForRegister] = useState();
    let ogForm = { birthName: '', surName: '', correo: '', móvil: '', MobileCode: '', país: '', MobileExt: '' }
    const [form, setForm] = useState(ogForm)
    const [countryData, setCountryData] = useState([])

    const [countryDataSearch, setCountryDataSearch] = useState({})

    const [otp, setOTP] = useState('');
    const [verifyOpen, setVerifyOpen] = useState(null);
    const [regLoader, setREGLoader] = useState(false);
    const [guestLoader, setGuestLoader] = useState(false);
    const [timeLoading, setTimeloading] = useState(true);

    const [teleLoading, setTeleloading] = useState(true);

    const [otpLoading, setOTPLoading] = useState(false)

    const [payload, setPayload] = useState({
        appointmentType: "",
        appointmentDate: "",
        appointmentTime: "",
        appointmentEndTime: "",
        appointmentFor: 1233,
        doctorName: ""
    })

    const [teleconsultationSlots, setTeleconsultationSlots] = useState()
    const [teleAvailDates, setTeleAvailDates] = useState()
    

    const [relatedConsultants, setRelatedConsultants] = useState([])
    let [loading, setLoading] = useState(false)
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [queryParams, setQueryParams] = useState({
        limit: 10,
        offset: 0
    })

    const [formValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className="field_error" > {message} </p>
    }
    )))
    const mobileValidator = customMobileValidator
    const [otpValidator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className="field_error" >The OTP field is required.</p>
    }
    )))

    if (data && data?.allNodeDoctor?.nodes?.[0]?.metatags) {
        metaTag = data?.allNodeDoctor?.nodes?.[0]?.metatag || []
    }

    const refFocus = useRef(null);

    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
        setGuestLoader(false)
        setREGLoader(false)
        setForm(ogForm)
        setVerifyOpen(false)
        setOTP('')
        formValidator.current.hideMessages()
        mobileValidator.current.hideMessages()
        otpValidator.current.hideMessages()
        forceUpdateForRegister(10)
    }

    const continueLogin = () => {
        closeModal()
        navigate(`/user/login`, { state: { appointmentData: payload } })
    }

    const onVerify = () => {
        const mobileResult = mobileValidator.current.allValid()
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(1)
            setVerifyOpen(false);
        } else {
            onResendOTP()
        }
    }

    const onResendOTP = () => {
        setOTPLoading(true)
        USER_API.sendOTP({ mobileNo: form.móvil, "otpFor": "quick_register" })
            .then(response => {
                setVerifyOpen(true);
                toast.success("OTP sucessfully sent on mobile no.")
                setOTPLoading(false)
            })
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong. Please try again later")
                setOTPLoading(false)
                setVerifyOpen(false);
            })
    }

    const validateOTP = () => {
        return USER_API.verifyOTP(otp)
            .then(res => {
                return res
            })
            .catch(err => {
                console.log(err)
                return false
            })
    }

    const continueRegister = async (guest) => {
        const formResult = formValidator.current.allValid()
        const mobileResult = mobileValidator.current.allValid()
        const otpResult = otpValidator.current.allValid()
        
        if (formResult === false) {
            formValidator.current.showMessages()
            forceUpdateForRegister(2)
        }
        if (mobileResult === false) {
            mobileValidator.current.showMessages()
            forceUpdateForRegister(3)
        }
        otp?.length === 0 && mobileResult && !verifyOpen &&  onVerify()

        if (otpResult === false || otp?.length === 0 && verifyOpen) {
            otpValidator.current.showMessages()
            forceUpdateForRegister(5)
        }

        if (formResult && mobileResult && otp?.length && !verifyOpen === 0) {
            toast.error("Please verify OTP before proceeding")
            return
        }
        if (formResult && mobileResult && otpResult && !form?.país) {
            toast.error("Please select country before proceeding")
            return
        }

        if (formResult && mobileResult && otpResult && otp?.length !== 0) {
            if (guest) {
                setGuestLoader(true)
            } else {
                setREGLoader(true)
            }
            const otpVerify = await validateOTP()
            if (otpVerify) {
                let ogPayload = {
                    RegType: 'EX',
                    ApptDate: payload?.appointmentDate,
                    FromTime: payload?.appointmentTime,
                    ToTime: payload?.appointmentEndTime,
                    DocCode: payload?.docCode,
                    PatFirstName: form?.birthName,
                    PatLastName: form?.surName,
                    PatMobNo: form?.móvil,
                    SlotType: payload?.appointmentType,
                    PatEmailId: form?.correo,
                    Age: "",
                    AgeType: "",
                    Gender: "",
                    Country_Code: form?.país
                }
                if (guest) {
                    bookAppointment(ogPayload, true)
                } else {
                    bookAppointment(ogPayload)
                }
            } else {
                setGuestLoader(false)
                setREGLoader(false)
                toast.error("Invalid OTP")
            }
        }
    }

    const bookAppointment = (ogPayload, guest) => {
        initHeaders()
        API.bookAnAppoinment(ogPayload)
            .then(res => {
                if (res) {
                    let data = res?.Data
                    let newForm = {
                        ...form,
                        Firstname: form.birthName,
                        Lastname: form.surName,
                        Email: form.correo,
                        Mobile: form.móvil,
                        Country: form.counrty_name
                    }
                    if (guest) {
                        navigate(`/confirm-pay`, { state: { apiData: data, formData: { ...newForm, otp, ...payload } } })
                    } else {
                        navigate(`/user/register`, { state: { apiData: data, formData: { ...newForm, otp, ...payload } } })
                    }
                }
                closeModal()
            }).catch(err => {
                console.log(err, err?.response)
                toast.error(err?.response?.data ? err.response.data : "Something went wrong. Please try again later")
                // closeModal()
                setGuestLoader(false)
                setREGLoader(false)
            })
    }

    const handleInputChange = (e) => {
        const { name, id, value } = e?.target;
        if (name === 'móvil') {
            let val = acceptOnlyNumbers(e.target.value)
            setForm({
                ...form,
                [name]: val,
            });
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    const handleSelectChange = (e) => {
        const val = e?.value
        const name = e?.label
        setForm({
            ...form,
            'país': val,
            'counrty_name':name
        });
    };


    const getCountryData = () => {
        USER_API.getAllCountry()
            .then(res => {
                if (res) {
                    setCountryData(res)
                    res?.map(item => {
                        // Assign new key
                        item['label'] = item['description']; 
                        item['value'] = item['code']; 
                
                        // Delete old key
                        delete item['description'];
                        delete item['code'];
                
                        return item;
                    })
                    setCountryDataSearch(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    
    const getTeleconsultationFunc = (date, drId) => {
        setTeleloading(true)
        let drdetailForTel = {
        "doctor_id": pageInfo.field_doctor_code || drId, //pageInfo.field_doctor_code || '',
        "consult_type": 1,
        "is_international": 0,
        "date": date}
        API.getTeleconsultation(drdetailForTel)
            .then(res => {
                if (res) {
                    let data = res
                    setTeleconsultationSlots(data?.time_slots?.all)
                    //setSelectedTeleDate()
                    setTeleloading(false)
                }
            }).catch(err => {
                console.log(err, err?.response)
            })
    }

    const getTeleAvailDatesData = async (drId) => {
        await API.getTeleAvailDates(drId)
            .then(res => {
                if (res && res?.status_code !== 404) {
                    let data = res?.dates.flat(1)
                    setTeleAvailDates(data.reduce((acc,curr)=> (acc[curr]={GEN: [{available:"1"},{from:"00:00"},{to:"00:00"}], PRV: null, TEL: null},acc),{}))
                    const selectedDate = data.length > 0 && data[0]
                    //setSelectedTeleDate(selectedDate)
                    getTeleconsultationFunc(selectedDate, drId)
                }
            }).catch(err => {
                console.log(err, err?.response)
            })
    }

    useEffect(() => {
        let info = {}
        getCountryData()
        // if (pageContext && pageContext.doctorData) {
        if (data && data.allNodeDoctor && data.allNodeDoctor.nodes) {
            const found = data.allNodeDoctor.nodes[0] //(data.allNodeDoctor.nodes.find(el => el.id === pageContext.doctorId))
            if (found) {
                info = found
                // info = pageContext.doctorData
                if (info) {
                    setPageInfo(info)
                    if (info?.relationships?.field_speciality && info?.relationships?.field_speciality?.length > 0) {
                        setCategory(info?.relationships?.field_speciality[0]?.name)
                        const queryParams = { ...queryParams, speciality: info?.relationships?.field_speciality[0]?.name }
                        setQueryParams({ ...queryParams, speciality: info?.relationships?.category?.name })
                        setSpeciality(info?.relationships?.field_speciality[0]?.name)
                        getData(queryParams, info)
                    }
                    if (info?.field_doctor_code && !info?.field_not_avail_online_booking) {
                        init(info?.field_doctor_code);
                    }
                }
            }
        }
        let pageBreadcrumb = ""
        if (info.title || info.firstName || info.lastName) {
            //${info.title ? info.title : ''}
            const name = `Dr.${' '}${info.firstName ? info.firstName : ''}${' '}${info.lastName ? info.lastName : "Doctor's Profile"}`
            pageBreadcrumb = name
        }

    }, [])

    const init = async (code) => {
        setLoading(true)
        setTimeloading(true)
        if (code) {
            await getBookingSlots(code).catch(err => {
                setLoading(false)
                setTimeloading(false)
            })

            await getTeleAvailDatesData(code).catch(err => {
                console.log(err)
                setLoading(false)
                setTimeloading(false)
            })

            const res = await API.getRoomNo(code).catch(err => {
                console.log(err)
                setLoading(false)
                setTimeloading(false)
            })
            
            if (res && res instanceof Array && res?.length) {
                //setRoomNo(res[0]) =========  // Temp
                // setLoading(false) =========  // Temp
                // setTimeloading(false) =======  // Temp
            }
            setLoading(false) // Temp
            setTimeloading(false) // Temp
        } else {
            setLoading(false)
            setTimeloading(false)
            toast.error("Doctor code missing. Please contact site admin.")
        }
    }

    useEffect(() => {
        if (bookingSlots) {
            API.getSlotTypes().then(r => {
                if (r?.length > 0) {
                    let data = r;
                    let arr = [];
                    
                    let supportedSlots = ['GEN', 'PRV', 'TEL', 'TST'];
                    supportedSlots.forEach(d => {
                        arr.push(API.getChargeBySlotType(d, pageInfo?.field_doctor_code))
                    })
                    Promise.all(arr)
                        .then(res => {
                            let respArr = []
                            res.forEach(el => {
                                let str = ''
                                let name = ''
                                let charge = ''
                                if(el && el.length > 0 && el !== 'Not found'){
                                    el?.forEach((elem, i) => {
                                        str = `${str}${elem?.description} - ₹${elem?.charge}${i === el?.length - 1 ? '.' : ','} `
                                        name = 'GEN'
                                        charge = elem?.charge
                                    })
                                }
                                let obj = {
                                    code: name,
                                    chargesStr: str,
                                    charges: charge
                                }
                                respArr.push(obj)
                            })
                            
                            respArr.forEach(elem => {
                                if(elem?.code !== ''){
                                    data.find(a => a.code === elem.code)['charges'] = elem.charges;
                                    data.find(a => a.code === elem.code)['chargesStr'] = elem.chargesStr;
                                }
                            })
                            setAvailableSlots(data) 
                            setLoading(false)
                        }).catch(err => {
                            console.log(err)
                            toast.error("There was an issue in loading the slot charges.")
                            setAvailableSlots(data)
                            setLoading(false)
                        })
                }
                
            }).catch(err => {
                toast.error("There was an issue in loading the slot types.")
                setLoading(false)
                console.log(err)
            })
        }

    }, [bookingSlots])

    const getBookingSlots = async (code) => {
        const response = await API.getBookingSlots(code).catch(err => {
            console.log(err)
        })
        if (response?.Data) {
            let r = {};
            response.Data.forEach(e => {
                r[e.date] = JSON.parse(JSON.stringify(e));
                delete r[e.date].date;
            })

            setBookingSlots(r)
            const selectedDate = Object.keys(r).length > 3 ? Object.keys(r)[3] : Object.keys(r)[0]
            setSelectedDate(selectedDate)
            setSelectedDateSlots(r[selectedDate])
        }
        return;
    }
    const getData = (query, info) => {
        setLoading(true)
        API.getDoctors(query)
            .then(response => {
                if (response && response?.data && response.data.length) {
                    getRelatedDocs(response, info)
                } else {
                    setShowNoDataText(true)
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
                if (info?.field_not_avail_online_booking === true) {
                    setLoading(false)
                }
                setShowNoDataText(true)
                setLoading(false)
            })
    }
    const getRelatedDocs = (response, info) => {
        let profileDrCode = info.field_doctor_code
        let arr = response.data.filter(el => getLowerCaseNoSpace(el.name) !== getLowerCaseNoSpace(info.firstName) + getLowerCaseNoSpace(info.lastName))
        let arr2 = arr.filter((item) => item.doctor_code !== profileDrCode);
        setRelatedConsultants(arr2)
        setShowNoDataText(false)
        setLoading(false)
        if (info?.field_not_avail_online_booking === true) {
            setLoading(false)
        }
    }
    const onViewAllClick = () => {
        navigate('/find-a-doctor?category=' + encodeURIComponent(speciality))
    }
    const onSlotSelect = (data, index, type, charges, slotTitle, slotId) => {
        if (data.available > 0 || data.is_blocked === false) {
            setSelectedSlot(type + "-" + index)
            const imgSrc = pageInfo.relationships?.image?.uri?.url ?
                pageInfo.relationships.image.uri?.url : null

            let dataTimeSlot = (data?.slot)?.split(' ')[1]
            let dataTimeSlotTo = addMinutesInTime(dataTimeSlot, '15')

            setPayload({
                ...payload,
                appointmentType: type,
                appointmentDate: selectedDate,
                appointmentTime: data.from || dataTimeSlot,
                appointmentEndTime: data.to || dataTimeSlotTo,
                docCode: pageInfo.field_doctor_code,
                docPath: pageInfo.path,
                image: imgSrc,
                charges: charges,
                description: slotTitle,
                doctorName: `Dr.${' '}${pageInfo.firstName ? pageInfo.firstName : ''}${' '}${pageInfo.lastName ? pageInfo.lastName : ''}`,
                slotId: slotId || ''
            })

            setTimeout(() => {
                refFocus.current.focus();
            }, 500);
        }
    }

    const onSlotBook = () => {
        if (!payload.appointmentDate || !payload.appointmentTime || !payload.appointmentEndTime) {
            toast.error("Please select a slot to continue.")
            return;
        }
        const user = getCookie("user")
        if(payload.appointmentType === 'GEN'){
            let blockPayload = {
                "DocCode": payload?.docCode,
                "ApptDate": payload?.appointmentDate,
                "FromTime": payload?.appointmentTime,
                "ToTime": payload?.appointmentEndTime,
                "SlotType": payload?.appointmentType,
                "SessionID": getCookie('_ga'),
                "Action": "B"
            }
        
            //API.blockTimeSlot(blockPayload)
                //.then(res => {
                    //if (res?.data?.Message === 'SUCCESS') {
                        //if (user && payload) {
                            //navigate(`/confirm-pay`, { state: { formData: payload, loginData: JSON.parse(user) } })
                        //} else {
                            openModal()
                        //}
                    // } else {
                    //     toast.error('Slot has been blocked by other user')
                    // }
                //})
                //.catch(err => {
                //    console.log('errads', err, err.reposne)
                //})
        }else{
            if (user && payload) {
                navigate(`/confirm-pay`, { state: { formData: payload, loginData: JSON.parse(user) } })
            } else {
                openModal()
            }
        }
    }

    const parentCallback = (val) => {
        setSelectedDate(val.ogDate)
        setSelectedDateSlots(bookingSlots[val.ogDate])
        setSelectedSlot("")
        //getTeleAvailDatesData(pageInfo.field_doctor_code || '')
    }
    const teleCallback = (val) => {
        setSelectedSlot("")
        //setSelectedTeleDate(val.ogDate)
        getTeleconsultationFunc(val.ogDate)
    }
    useEffect(() => {
        if (loading === false && window?.initAccordionOpen && pageInfo?.field_not_avail_online_booking === true) {
            window?.initAccordionOpen()
        }
    }, [loading])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [showModal])

    useScript("/assets/calendarPicker/jquery.calendarPicker.js");
    useScript("/assets/calendarPicker/test/jquery.mousewheel.js");
    useScript("/assets/js/calendarPicker.js");
    useScript("/assets/js/login-register.js");
    useScript("/assets/js/custom_select.js");

    return (
        <Layout>
            <Meta
                files={
                    {
                        js: [],
                        css: [
                            "/assets/css/doctor-profile.css",
                            "/assets/calendarPicker/jquery.calendarPicker.css",
                            "/assets/css/listing.css",
                            "/assets/css/common.css"
                        ]
                    }
                }
                tags={metaTag}
            />
            <Modal
                show={showModal}
                animation={false}
                centered
                size="lg"
                className="bookAppointmentModal"
                dialogClassName="modal_video modal common_modal"
            >
                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <Modal.Body>
                    <div class="appointmentWrap">
                        <div class="leftBox">
                            <div class="boxContent">
                                <p>Already Registered with P.D. Hinduja Hospital & Medical Research Centre</p>
                                <p>Fetch Details</p>
                                <APIButton
                                    title={`Click HERE`}
                                    disabled={regLoader || guestLoader}
                                    onBtnClick={() => continueLogin()}
                                    className={`btn btn-primary-solid-white`}
                                />
                                {/* <a href="javascript:;" class="btn btn-primary-solid-white">LOGIN HERE</a> */}
                            </div>
                        </div>
                        <div class="rightBox">
                            <h3>Not Registered? Please provide below details.</h3>
                            <div class="form_wrap">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div className={`form-group -animated ${form.birthName ? '-active' : ''}`}>
                                            <label>First Name <span className="mandatory">*</span></label>
                                            <input onChange={handleInputChange} autoComplete={'off'} name="birthName" value={form.birthName} className="form-control" type="text" />
                                            {formValidator.current.message('First name', form.birthName, 'required|alpha')}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div className={`form-group -animated ${form.surName ? '-active' : ''}`}>
                                            <label>Last Name <span className="mandatory">*</span></label>
                                            <input onChange={handleInputChange} autoComplete={'off'} name="surName" value={form.surName} className="form-control" type="text" />
                                            {formValidator.current.message('Last name', form.surName, 'required|alpha')}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div className={`form-group -animated ${form.correo ? '-active' : ''}`}>
                                            <label>Email Address <span className="mandatory">*</span></label>
                                            <input onChange={handleInputChange} autoComplete={'off'} name="correo" value={form.correo} className="form-control" type="text" />
                                            {formValidator.current.message('Email', form.correo, 'required|email')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group select-dd">
                                            <label>Country <span className="mandatory">*</span></label>
                                            <Select name="país" options={countryDataSearch} onChange={handleSelectChange} className="selectWithSearch" placeholder="Select Country" />
                                            {formValidator.current.message('Country', form.país, 'required')}
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className={`form-group -animated -active`}>
                                            <label>Mobile <span className="mandatory">*</span></label>
                                            <PhoneInput
                                                className="mt-2"
                                                autoComplete={'off'}
                                                preferredCountries={['us','in']}
                                                country={'in'}
                                                value={form.MobileExt}
                                                countryCodeEditable={false}
                                                disabled={verifyOpen}
                                                placeholder={''}
                                                onChange={(value, country, e, formattedValue) => {
                                                    setForm({
                                                        ...form,
                                                        ['MobileCode'] : country?.dialCode,
                                                        ['móvil'] : value.substring(country?.dialCode?.length)
                                                    })
                                                }}
                                                inputProps={{
                                                    name: 'móvil',
                                                    required: true,
                                                }}
                                            />
                                            {mobileValidator.current.message('Mobile ', form.móvil , 'required|min:10|max:10||validateMobile')}
                                            {/*!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />*/}
                                        </div>
                                    </div>

                                    <MobileVerifyViaOTP
                                        form={form}
                                        setForm={setForm}
                                        otp={otp}
                                        setOTP={setOTP}
                                        verifyOpen={verifyOpen}
                                        mobileValidator={mobileValidator}
                                        otpValidator={otpValidator}
                                        onVerify={onVerify}
                                        otpLoading={otpLoading}
                                        onResendOTP={onResendOTP}
                                    />
                                    
                                    {/* <div className="col-lg-12 col-md-12">
                                    <div className="row otp_row">
                                        <div className="col-md-6">
                                            <div className={`form-group -animated -active`}>
                                                <label>Mobile <span className="mandatory">*</span></label>
                                                <PhoneInput
                                                    className="mt-2"
                                                    autoComplete={'off'}
                                                    preferredCountries={['us','in']}
                                                    country={'in'}
                                                    value={form.MobileExt}
                                                    countryCodeEditable={false}
                                                    placeholder={''}
                                                    onChange={(value, country, e, formattedValue) => {
                                                        setForm({
                                                            ...form,
                                                            ['MobileCode'] : country?.dialCode,
                                                            ['móvil'] : value.substring(country?.dialCode?.length)
                                                        })
                                                    }}
                                                    inputProps={{
                                                        name: 'móvil',
                                                        required: true,
                                                    }}
                                                />
                                                {mobileValidator.current.message('Mobile ', form.móvil , 'required|min:10|max:10')}
                                                {!verifyOpen && <LinkButton title={`Verify with OTP`} onBtnClick={onVerify} loading={otpLoading} />}
                                            </div>
                                        </div>
                                        {verifyOpen &&
                                            <div className="col-md-4 mb-2">
                                                <div className={`form-group -animated ${otp ? '-active' : ''}`}>
                                                    <label>Enter OTP <span className="mandatory">*</span></label>
                                                    <input className="form-control" type="text" id="OTP" value={otp} onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))}/>
                                                    {otpValidator.current.message('OTP', otp, 'required')}
                                                </div>
                                                <LinkButton title={`Resend OTP`} resend onBtnClick={onResendOTP} loading={otpLoading} />
                                            </div>
                                        }
                                    </div>
                                </div>   */}
                                </div>
                                <div class="btn_wrap">
                                    <APIButton
                                        title={`QUICK REGISTER`}
                                        loading={regLoader || otpLoading}
                                        disabled={regLoader}
                                        onBtnClick={() => continueRegister(false)}
                                        className={`btn btn-primary-solid`}
                                    />
                                    {/* <button class="btn btn-primary">QUICK REGISTER</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {loading ?
                <Loader loadingMessage="Loading..." />
                :
                <main className="innerpage">
                    {pageInfo?.relationships?.field_breadcrumb_component &&
                        <section className="breadcrumbs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ul>
                                            {pageInfo?.relationships?.field_breadcrumb_component?.field_breadcrumb_link?.map((el, index) => {
                                                return (
                                                    <li key={index}>
                                                        <CustomLink data={{ link: el }}>{el.title}</CustomLink>
                                                    </li>
                                                )
                                            })
                                            }
                                            <li><span>{pageInfo?.relationships?.field_breadcrumb_component?.field_title}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <section className="banner_section gradient_bg">
                        <div className="Inner_banner_content">
                            <div className="container bg-plus">
                                <h2>{category}</h2>
                                <div className="row">
                                    <div className="col-lg-7 col-md-6 col-6">
                                        <div className="banner_content">
                                            <div className="title">
                                                <h1>
                                                    {
                                                        // `${category !== 'Physiotherapy & Rehabilitation' ? 'Dr. ' : ''}${pageInfo.firstName ? pageInfo.firstName : ''}${' '}${pageInfo.lastName ? pageInfo.lastName : ''}`
                                                        `${pageInfo.title ? pageInfo.title : ''}`
                                                    }
                                                </h1>
                                                {
                                                    pageInfo.designation &&
                                                    <h4>{pageInfo.designation}</h4>
                                                }
                                                {
                                                    pageInfo.qualifications &&
                                                    <h6>{pageInfo.qualifications}</h6>
                                                }
                                            </div>
                                            <div className="description">
                                                <h5>
                                                    <img src="/assets/images/icons/icon_area_of_expertise.svg" alt="" />
                                                    Areas of Expertise
                                                </h5>
                                                {
                                                    pageInfo.areasOfExpertise &&
                                                    <p>{pageInfo.areasOfExpertise}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-6">
                                        <picture className="hero_image text-center">
                                            {
                                                pageInfo &&
                                                <ImageComponent
                                                    content={pageInfo}
                                                />
                                            }
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-py section-bg">

                        <div className="container">
                            {pageInfo?.field_not_avail_online_booking === false &&
                                <ul class="nav nav-tabs gallery-tabs" role="tablist">
                                    <li role="presentation" class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#consultationTab" role="tab" aria-controls="consultation" aria-selected="true">Consultation at Hospital</a>
                                    </li>
                                    <li role="presentation" class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#tele-consultation" role="tab" aria-controls="tele-consultation" aria-selected="false">Tele Consultation</a>
                                    </li>
                                </ul>
                            }
                            <div class="tab-content mt-3">  
                                <div class="tab-pane active" id="consultationTab" role="tabpanel" aria-labelledby="consultation">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12">
                                            <div className="accordion_container">
                                                <div id="accordion" role="tablist" className="common_accordion custom-accordion">
                                                    {pageInfo?.field_not_avail_online_booking === false &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingOne">
                                                                <h5 className="mb-0">
                                                                    <a data-toggle="collapse" href="#weeklyTimings" aria-expanded="true" aria-controls="weeklyTimings">
                                                                        Doctor’s Weekly Timings {roomNo && <span>Room No {roomNo}</span>}
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="weeklyTimings" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                                                <div className="card-body">
                                                                    {timeLoading
                                                                        ? <p>Please wait as slots are loading...</p>
                                                                        : <>
                                                                            {(bookingSlots && selectedDateSlots) ?
                                                                                <div className="calander_wrap">
                                                                                    {bookingSlots && <DatePicker data={bookingSlots} taleAvailDates={teleAvailDates} parentCallback={parentCallback} />}
                                                                                    <br />
                                                                                    {/* 
                                                                    <div id="dsel2" className="w-100 profile"></div>
                                                                    <br /> */}
                                                                                    {/* <!-- <span id="wtf"></span> --> */}
                                                                                    <div className="booking_slot">
                                                                                        {
                                                                                            selectedDateSlots &&
                                                                                            <>
                                                                                                <div className="booking_list">
                                                                                                    {availableSlots.map((slot, index) => {
                                                                                                        if (selectedDateSlots[slot.code] && selectedDateSlots[slot.code]?.length) {
                                                                                                            return (
                                                                                                                <div className="list_box" key={index}>
                                                                                                                    <h3><span>{slot.description === "CONSULTANTS" ? 'Consultation at hospital' : slot.description}</span> {slot.chargesStr && <p className="note mb-0">(fees : {slot.chargesStr})</p>}</h3>
                                                                                                                    <div class="bookSlots_section">
                                                                                                                        <div class="bookSlots_bg">
                                                                                                                            <span class="orange_bg">Available Slots</span>
                                                                                                                            <span class="gray_bg">Slots Already Booked</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {selectedDateSlots[slot.code]
                                                                                                                        ?
                                                                                                                        <ul>
                                                                                                                            {
                                                                                                                                selectedDateSlots[slot.code].map((el, index) => {
                                                                                                                                    if (el.from && el?.to) {
                                                                                                                                        return (
                                                                                                                                            <li key={slot.code + "-" + index}>
                                                                                                                                                <a
                                                                                                                                                    onClick={() => onSlotSelect(el, index, slot.code, slot.charges, slot.description)}
                                                                                                                                                    className={`${(slot.code + "-" + index) === selectedSlot ? "active" : (el.available > 0) ? "available" : "disabled"}`}
                                                                                                                                                    href="javascript:void(0)"
                                                                                                                                                    title={el.available <= 0 ? "No Slots Available" : null}
                                                                                                                                                >{format24To12HoursTime(el.from)} - {format24To12HoursTime(el.to)}</a>
                                                                                                                                            </li>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                        :
                                                                                                                        null
                                                                                                                    }

                                                                                                                    

                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="btn_wrap text-center">
                                                                                                    <a href="javascript:void(0)" ref={refFocus} onClick={onSlotBook} className="btn btn-primary">Book Slot</a>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <p>This doctor only holds In-Patient Admissions. Please call <a href="022-61746000">022-61746000</a> / <a href="tel:022-4888 4000">022-4888 4000</a> to book a procedure</p>
                                                                            }
                                                                        </>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.about && pageInfo.about.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingTwo">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#aboutDoctor" aria-expanded="false" aria-controls="aboutDoctor">
                                                                        About the Doctor
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="aboutDoctor" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.about.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.specialTraining && pageInfo.specialTraining.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingThree">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#specialTraining" aria-expanded="false" aria-controls="specialTraining">
                                                                        Special Training
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="specialTraining" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.specialTraining.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.professionalExp && pageInfo.professionalExp.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading4">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#ProfessionalExperience" aria-expanded="false" aria-controls="ProfessionalExperience">
                                                                        Professional Experience and Training
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="ProfessionalExperience" className="collapse" role="tabpanel" aria-labelledby="heading4" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.professionalExp.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.professionalAff && pageInfo.professionalAff.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading5">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#ProfessionalAffiliations" aria-expanded="false" aria-controls="ProfessionalAffiliations">
                                                                        Professional Affiliations
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="ProfessionalAffiliations" className="collapse" role="tabpanel" aria-labelledby="heading5" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.professionalAff.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.editorShip && pageInfo.editorShip.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading6">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#Editorship" aria-expanded="false" aria-controls="Editorship">
                                                                        Editorship of Journals & Books
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="Editorship" className="collapse" role="tabpanel" aria-labelledby="heading6" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.editorShip.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.hobnors && pageInfo.hobnors.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading7">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#HonoursAwards" aria-expanded="false" aria-controls="HonoursAwards">
                                                                        Honours & Awards
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="HonoursAwards" className="collapse" role="tabpanel" aria-labelledby="heading7" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.hobnors.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.researchExp && pageInfo.researchExp.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading8">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#ResearchExperience" aria-expanded="false" aria-controls="ResearchExperience">
                                                                        Research Experience
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="ResearchExperience" className="collapse" role="tabpanel" aria-labelledby="heading8" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.researchExp.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.conferences && pageInfo.conferences.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading9">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#Conferences" aria-expanded="false" aria-controls="Conferences">
                                                                        Conferences
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="Conferences" className="collapse" role="tabpanel" aria-labelledby="heading9" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.conferences.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (pageInfo.publications && pageInfo.publications.processed) &&
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="heading10">
                                                                <h5 className="mb-0">
                                                                    <a className="collapsed" data-toggle="collapse" href="#Publications" aria-expanded="false" aria-controls="Publications">
                                                                        Publications
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="Publications" className="collapse" role="tabpanel" aria-labelledby="heading10" data-parent="#accordion">
                                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.publications.processed }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="consultants_cards inner_three_cards dr-listing-boxes p-0">
                                                {relatedConsultants && relatedConsultants.length > 0 ?
                                                    <h2>Consultants</h2>
                                                    : null}
                                                {
                                                    (relatedConsultants && relatedConsultants.length > 0) &&
                                                    <>
                                                        {relatedConsultants.slice(0, 3).map((el, index) => {
                                                            let p_n_r;
                                                            if (el?.speciality) {
                                                                Object.keys(el?.speciality).forEach(i => {
                                                                    p_n_r = el?.speciality[i]?.deptName === 'Physiotherapy & Rehabilitation';
                                                                })
                                                            }

                                                            return (
                                                                <div className="card" key={index}>
                                                                    <div className="card_image">
                                                                        <span className="imageCircle">
                                                                            <img src={el?.image || defaultImage} alt={el.name || ""} className="lazyload" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="top_title">
                                                                            <h3>
                                                                                {/* {!p_n_r && "Dr. "} */}
                                                                                {el?.name || ""}
                                                                            </h3>
                                                                            <span className="sub_title mb-1"><strong>{el?.designation || ''}</strong></span>
                                                                            <span className="sub_title">{el?.qualification || ''}</span>
                                                                            <p>{el?.experience || ''}</p>
                                                                        </div>
                                                                        <div className="card_link">
                                                                            {
                                                                                el.path &&
                                                                                <Link to={el.path} className="btn btn-primary">
                                                                                    {el.avail_for_online_booking !== "1" ? "Book Appointment" : "View Profile"}
                                                                                </Link>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {
                                                            relatedConsultants.length > 3
                                                                ?
                                                                <div className="btn_wrap">
                                                                    <a onClick={onViewAllClick} href="javascript:void(0)" className="btn btn-primary">View all</a>
                                                                </div>
                                                                : null
                                                        }
                                                    </>
                                                }
                                                {
                                                    showNoDataText === true &&
                                                    <p>Currently there is no data</p>
                                                }
                                                {/* <div className="quality_healthcare">
                                                    <div className="common_card bg-white">
                                                        <h3>Quality Healthcare</h3>
                                                        <ul className="d-flex" role="listbox">
                                                            <li role="listitem"><img src="/assets/images/quality_healthcare_03.png" alt="" /></li>
                                                            <li role="listitem"><img src="/assets/images/quality_healthcare_02.png" alt="" /></li> */}
                                                            {/* <li role="listitem"><img src="/assets/images/nabl-logo.png" alt="NABL Logo" /></li> */}
                                                            {/* <li role="listitem"><img src="/assets/images/footer/nabl.png" alt="NABL Logo" /></li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tele-consultation" role="tabpanel" aria-labelledby="tele-consultation">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12">
                                            <div className="accordion_container">
                                                <div id="accordion2" role="tablist" className="common_accordion custom-accordion">
                                                    
                                                        <div className="card">
                                                            <div className="card-header" role="tab" id="headingOne">
                                                                <h5 className="mb-0">
                                                                    <a data-toggle="collapse" href="#weeklyTimings" aria-expanded="true" aria-controls="weeklyTimings">
                                                                        Doctor’s Weekly Timings {roomNo && <span>Room No {roomNo}</span>}
                                                                    </a>
                                                                </h5>
                                                            </div>
                                                            <div id="weeklyTimings" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                                                <div className="card-body">
                                                                    {timeLoading
                                                                        ? <p>Please wait as slots are loading...</p>
                                                                        : <>
                                                                            {(teleconsultationSlots) ?
                                                                                <div className="calander_wrap">
                                                                                    {teleAvailDates && <TeledatePicker data={teleAvailDates} teleCallback={teleCallback} />}
                                                                                    <br />
                                                                                    <div className="booking_slot">
                                                                                        {
                                                                                            <>
                                                                                                <div className="booking_list">
                                                                                                    {teleconsultationSlots && teleconsultationSlots?.length ?
                                                                                                        <div className="list_box">
                                                                                                            <h3 className="mb-0"><span>Teleconsultation</span></h3>
                                                                                                            {/* <div class="bookSlots_section">
                                                                                                                <div class="bookSlots_bg">
                                                                                                                    <p class="greenText"><span>T</span> - Tele-consultation Days</p>
                                                                                                                </div>
                                                                                                            </div> */}
                                                                                                            {teleLoading
                                                                                                                ? <p>Please wait as slots are loading...</p>
                                                                                                                : <ul>
                                                                                                                    {
                                                                                                                        teleconsultationSlots.map((el, index) => {
                                                                                                                            if (el.slot) {
                                                                                                                                let slotType = 'TEL'
                                                                                                                                return (
                                                                                                                                    <li key={el.id}>
                                                                                                                                        <a
                                                                                                                                            onClick={() => onSlotSelect(el, index, slotType, el.feesInt, 'TELE CONSULTATION', el.id)}
                                                                                                                                            className={`${(slotType + "-" + index) === selectedSlot ? "active" : (el.is_blocked === true) ? "disabled" : "available"}`}
                                                                                                                                            href="javascript:void(0)"
                                                                                                                                            title={el.is_blocked === true ? "No Slots Available" : null}
                                                                                                                                        >{format24To12HoursTime( (el.slot).split(' ')[1] +' '+ (el.slot).split(' ')[1] )}</a>
                                                                                                                                    </li>
                                                                                                                                    
                                                                                                                                )
                                                                                                                            }
                                                                                                                        })
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            }
                                                                                                        </div>
                                                                                                    : null}
                                                                                                </div>
                                                                                                <div className="btn_wrap text-center">
                                                                                                    <a href="javascript:void(0)" ref={refFocus} onClick={onSlotBook} className="btn btn-primary">Book Slot</a>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <p>No data available.</p>
                                                                            }
                                                                        </>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            }
        </Layout>
    )
}
Profile.propTypes = {
    data: PropTypes.object.isRequired
}
export const query = graphql`
query ($doctorId: String, $categoryId: String) {

    allNodeDoctor(filter: {id: {eq: $doctorId}}) {
  
      nodes {
  
        id
  
        path {
  
          alias
  
        }
  
        metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
        }
  
        title
  
        firstName: field_first_name
  
        lastName: field_last_name
  
        designation: field_designation
  
        qualifications: field_qualifications
  
        
  
        image: field_dr_profile_image_for_deskt {
  
          alt
  
        }
  
        areasOfExpertise: field_areas_of_expertise
  
        about: body {
  
          processed
  
        }
        
         specialTraining: field_special_training {
  
          processed
  
        }
        
        professionExpTraining: field_professional_exp_training {
          
          processed
          
        }
        
         editorShipBooks: field_editorship {
          
          processed
          
        }
        
        conferences: field_conferences {
					processed
        }
        
        professionalAff: field_professional_affiliations {
  
          processed
  
        }
  
        hobnors: field_honours_awards {
  
          processed
  
        }
  
        researchExp: field_research_experience {
  
          processed
  
        }
  
        publications: field_publications {
  
          processed
  
        }
  
        relationships {
  
          image: field_dr_profile_image_for_deskt {
  
            id
  
            uri {
  
              value
  
              url
  
            }
  
          }
  
          category: field_category {
  
            id
  
            name
  
          }
  
          field_speciality {
  
            id
  
            name
  
          }
  
          field_breadcrumb_component {
  
            field_title
  
            field_breadcrumb_link {
  
              uri
  
              title
  
            }
  
          }
  
        }
  
        field_doctor_code
  
        field_not_avail_online_booking
  
      }
  
    }
  
    categoryDoctor: allNodeDoctor(
  
      filter: {relationships: {field_category: {id: {eq: $categoryId}}}}
  
    ) {
  
      nodes {
  
        id
  
        path {
  
          alias
  
        }
  
        metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
        }
  
        title
  
        firstName: field_first_name
  
        lastName: field_last_name
  
        designation: field_designation
  
        qualifications: field_qualifications
  
        image: field_dr_profile_image_for_deskt {
  
          alt
  
        }
  
        areasOfExpertise: field_areas_of_expertise
  
        about: body {
  
          processed
  
        }
  
        professionalAff: field_professional_affiliations {
  
          processed
  
        }
  
        hobnors: field_honours_awards {
  
          processed
  
        }
  
        researchExp: field_research_experience {
  
          processed
  
        }
  
        publications: field_publications {
  
          processed
  
        }
  
        relationships {
  
          image: field_dr_profile_image_for_deskt {
  
            id
  
            uri {
  
              value
  
              url
  
            }
  
          }
  
          category: field_category {
  
            id
  
            name
  
          }
  
          field_speciality {
  
            id
  
            name
  
          }
  
          field_breadcrumb_component {
  
            field_title
  
            field_breadcrumb_link {
  
              uri
  
              title
  
            }
  
          }
  
        }
  
        field_doctor_code
  
        field_not_avail_online_booking
  
      }
  
    }
  
  }
`
export default Profile